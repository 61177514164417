@import "../../styles/common.scss";

.hosp_sec1 {
  padding: 7.5em 0 0;
}

.hosp_sec2 {
  padding: 9.75em 0 11.875em;
}

.sports-venture-dynamic {
  .section-content {
    .section-description {
      padding-bottom: 2em;
    }
  }
  .card_img_row {
    margin: 0 auto;
    padding-top: 0;
    row-gap: 2.5em;
    .card_img_col {
      .img_wrapper {
        padding: 0;
        height: 100%;
        border: none;
        display: flex;
        flex-direction: column;
        .card_img_wrapper {
          border: 1px solid #929191;
        }
        .card_img {
          padding: 1.1em;
        }
        .team_content {
          padding: 1.375em;
          border: 1px solid #929191;
          border-top: none;
          height: 100%;
          .team-name {
            color: #000000;
            font-size: 1.6em;
            font-weight: 600;
            font-family: "Poppins", sans-serif;
            margin-bottom: 0.75em;
          }
          .team-description {
            font-size: 0.8em;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 1.75;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
  }
  .log_row {
    padding-top: 0;
  }
}

@media (max-width: 1536px) {
  .sports-venture-dynamic {
    .card_img_row {
      .card_img_col {
        .img_wrapper {
          .team_content {
            .team-description {
              font-size: 1em;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .hosp_sec1 {
    padding: 6em 0 0;

    .my_container {
      padding: 0 16px;
    }
  }

  .hosp_sec2 {
    padding: 6em 0 6em;

    .my_container {
      padding: 0 16px;
    }
  }

  .sports-venture-dynamic {
    .section-content {
      .section-description {
        padding-bottom: 1.25em;
      }
    }
    .card_img_row {
      row-gap: 0;
      .card_img_col {
        .img_wrapper {
          .card_img {
            padding: 1.25em;
          }
          .team_content {
            padding: 1.375em;
          }
        }
      }
    }
  }
}
