@import "../../styles/common.scss";

.hosp_sec1 {
  padding: 7.5em 0 0;
}

.hosp_sec2 {
  padding: 9.75em 0 11.875em;
}

@media (max-width: 767px) {
  .hosp_sec1 {
    padding: 6em 0 0;
    .my_container {
      padding: 0 16px;
    }
  }
  .hosp_sec2 {
    padding: 6em 0 6em;
    .my_container {
      padding: 0 16px;
    }
  }
}
