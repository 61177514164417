@import "../../styles/common.scss";

.business-venture-dynamic {
    padding: 0 0 11.875em;
  .hosp_sec1 {
    padding: 7.5em 0 0;
  }

  .hosp_sec2 {
    padding: 9.75em 0 0;
  }

  .sports-venture-dynamic {
    padding: 7.5em 0 0;
  }
}

@media (max-width: 767px) {
  .business-venture-dynamic {
    padding: 0 0 6em;
    .hosp_sec1 {
      padding: 6em 0 0;

      .my_container {
        padding: 0 16px;
      }
    }

    .hosp_sec2 {
      padding: 6em 0 0;

      .my_container {
        padding: 0 16px;
      }
    }
  }
}
